import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
}));

export const WishHeader = (props: any) => {
  const { isLoading, mutate } = props;
  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="center" width="58px"></TableCell>
            <TableCell className={classes.cell} align="center" width="150px"><span className={classes.heading}>CEGID N<sup>o</sup></span></TableCell>
            <TableCell className={classes.cell} align="left" width="250px"><span className={classes.heading}>Name</span></TableCell>
            <TableCell className={classes.cell} align="left" width="180px"><span className={classes.heading}>Phone</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Product</span></TableCell>
            <TableCell className={classes.cell} align="right" width="120px"><span className={classes.heading}>Price</span></TableCell>
            <TableCell className={classes.cell} align="center" width="180px"><span className={classes.heading}>Advisor</span></TableCell>
            <TableCell className={classes.cell} align="right" width="200px">
              {isLoading && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoading && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </>
  );
};
