import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { useAuthStore } from '../../hooks/context/useAuthStore';

import appLogo from '../../assets/logos/leatherwish-logo.png';
import overviewIcon from '../../assets/nav/overview-icon.svg';
import listIcon from '../../assets/nav/list-icon.svg';
import calendarIcon from '../../assets/nav/calendar-icon.svg';
import bagIcon from '../../assets/nav/bag-icon.svg';
import coinIcon from '../../assets/nav/coin-icon.svg';
import activityIcon from '../../assets/nav/activity-icon.svg';
import personIcon from '../../assets/nav/person-icon.svg';
// import vaultIcon from '../../assets/nav/wallet-icon.svg';
import settingsIcon from '../../assets/nav/settings-icon.svg';
import logoutIcon from '../../assets/nav/logout-icon.svg';
import loginIcon from '../../assets/nav/login-icon.svg';

import { MenuItem, MenuItemData } from './MenuItem';
import { LogoutItem } from './LogoutItem';

import { List, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { version } from '../../version';
const APP_NAME = 'LeatherWish';
const APP_VERSION = version;

const PROJECT_ENV = process.env.REACT_APP_PROJECT_ENV || '';
const APP_CLIENT = (PROJECT_ENV === 'prod' ? 'pilot' : PROJECT_ENV);
console.log(`${APP_NAME} v${APP_VERSION} (${APP_CLIENT})`)

interface IMenu {
  isOpen?: boolean;
  onClose?: () => void;
  isMobile?: boolean;
}

const useStyles = makeStyles<IMenu>()((theme: any, props: IMenu) => ({
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: (props.isOpen ? '0 30px' : '0 10px'),
  },
  divider: {
    height: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
  logoWrapper: {
    display: 'flex',
    height: 105,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
    marginBottom: 33,
    cursor: 'pointer',
    padding: 10,
  },
  mobileLogo: {
    padding: 10,
  },
  logoImgContainer: {
    maxWidth: '40px'
  },
  logo: {
    width: '100%',
    height: 'auto'
  },
  logoTitle: {
    marginTop: 0,
    color: theme.palette.secondary.contrastText,
    fontFamily: 'Roboto Slab',
    fontSize: '1.375rem',
    fontWeight: 500,
    marginBlockStart: 0,
    '& span': {
      color: 'rgba(255, 255, 255, 0.5)',
      fontFamily: 'Roboto',
      fontWeight: 400,
      letterSpacing: '0px',
    }
  },
  logoutWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuWrapper: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80vh',
    overflow: 'scroll',
    display: 'flex',
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.secondary.main}`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  beta: {
    width: 36,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    borderRadius: 4,
    top: 12,
    right: 72,
    marginTop: (props.isOpen ? '0' : '10px'),
    position: (props.isOpen ? 'absolute' : 'initial'),
  },
  version: {
    marginTop: 0,
    fontFamily: 'Roboto',
    fontSize: '0.7em',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.7)',
    fontWeight: 300,
    letterSpacing: '1px',
    '& span': {
      color: 'rgba(255, 255, 255, 0.5)',
      fontFamily: 'Roboto Mono',
      fontWeight: 200,
      letterSpacing: '0px',
    }
  },
}));

export const Menu = ({ isOpen, onClose, isMobile }: IMenu) => {
  const { classes } = useStyles({ isOpen });

  const { isAuthorized, currentUser, logout } = useAuthStore();
  const navigate = useNavigate();

  const overviewUrl = isAuthorized ? '/overview' : '/signin';
  const mainIconUrl = overviewUrl;

  const doSignIn = async () => {
    try {
      navigate('/');
    } catch (e) { }
  };

  const isMenu = (menuItem: MenuItemData) => {
    if (menuItem.isAdministrator && currentUser.isAdministrator) return true;
    if (menuItem.isManager && currentUser.isManager) return true;
    if (menuItem.isReviewer && currentUser.isReviewer) return true;
    if (menuItem.IsAdvisor && currentUser.IsAdvisor) return true;
    return false;
  };

  const menu: Array<MenuItemData> = [
    {
      icon: overviewIcon,
      name: 'Overview',
      to: overviewUrl,
      onClick: onClose,
      isAdministrator: true,
      isManager: true,
      isDirector: true,
      isReviewer: true,
      IsAdvisor: true,
    },
    {
      icon: listIcon,
      name: 'Wishes',
      to: '/wish',
      onClick: onClose,
      isAdministrator: true,
      isManager: true,
      isDirector: true,
      isReviewer: true,
      IsAdvisor: true,
    },
    {
      icon: calendarIcon,
      name: 'Schedule',
      to: '/schedule',
      onClick: onClose,
      isAdministrator: true,
      isDirector: true,
      isManager: true,
      isReviewer: false,
      IsAdvisor: false,
    },
    {
      icon: coinIcon,
      name: 'Planning',
      to: '/planning',
      onClick: onClose,
      isAdministrator: true,
      isDirector: true,
      isManager: false,
      isReviewer: false,
      IsAdvisor: false,
    },
    {
      icon: activityIcon,
      name: 'Activity',
      to: '/activity',
      onClick: onClose,
      isAdministrator: true,
      isDirector: true,
      isManager: true,
      isReviewer: false,
      IsAdvisor: false,
    },
    {
      icon: bagIcon,
      name: 'Products',
      to: '/products',
      onClick: onClose,
      isAdministrator: true,
      isDirector: true,
      isManager: false,
      isReviewer: false,
      IsAdvisor: false,
    },
    {
      icon: personIcon,
      name: 'Users',
      to: '/user',
      onClick: onClose,
      isAdministrator: true,
      isDirector: true,
      isManager: false,
      isReviewer: false,
      IsAdvisor: false,
    },
    {
      icon: settingsIcon,
      name: 'Settings',
      to: '/settings',
      onClick: onClose,
      isAdministrator: true,
      isDirector: true,
      isManager: true,
      isReviewer: true,
      IsAdvisor: true,
    },
  ];

  return (
    <>
      <div className={clsx(classes.logoWrapper, isMobile && classes.mobileLogo)}>
        <div className={classes.logoImgContainer} onClick={() => navigate(mainIconUrl)}>
          <img src={appLogo} alt='' className={classes.logo} />
        </div>
        {isOpen && (
          <>
            <Typography variant='h3' className={classes.logoTitle}>LEATHER<span>WISH</span></Typography>
            <div className={classes.version}>{APP_CLIENT} <span>v{APP_VERSION}</span></div>
          </>
        )}
      </div>
      <div className={classes.menuWrapper}>
        <List className={classes.containerItem}>
          {menu.filter((menuItem: MenuItemData) => isMenu(menuItem)).map((menuItem: MenuItemData) => (
            <MenuItem
              isDrawerOpen={isOpen}
              menuItem={menuItem}
              key={menuItem.name}
              id={menuItem.name}
              className={menuItem.className || ''}
            />
          ))}
        </List>
        {isAuthorized && (
          <List className={clsx(classes.logoutWrapper, classes.containerItem)}>
            <LogoutItem
              isDrawerOpen={isOpen}
              menuItem={{
                icon: logoutIcon,
                name: 'Logout',
                onClick: () => logout('', ''),
              }}
              key={'Logout'}
              id={'Logout'}
              openSideBar={true}
            />
          </List>
        )}
        {!isAuthorized && (
          <List className={clsx(classes.logoutWrapper, classes.containerItem)}>
            <LogoutItem
              isDrawerOpen={isOpen}
              menuItem={{
                icon: loginIcon,
                name: 'Login',
                onClick: doSignIn,
              }}
              key={'Login'}
              id={'Login'}
              openSideBar={true}
            />
          </List>
        )}
      </div>
    </>
  );
}
