import { useState, useEffect } from 'react';

import { EState } from '../../services/types';
import { stateWish } from '../../services/wish';

import { AppMessage } from '../UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    fontWeight: 300,
    fontSize: '1em',
  },
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(0.5)
  },
}));

export const StateWishModal = (props: any) => {
  const [state, setState] = useState('');

  const [errorState, setErrorState] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearForm = () => {
    setState(props.selectedWish?.state || EState.created);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorState('');
  };

  useEffect(() => {
    clearForm();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedGroup, props?.selectedWish]);

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });

    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleState();
    }
  };

  const handleState = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating Wish: <strong>${props.selectedWish?.name}</strong> ... ` });
    stateWish(props.selectedWish.groupId, props.selectedWish.wishId, state).then((result: any) => {
      if (result.status) {
        setMessage({ type: 'success', description: `Wish: <strong>${props.selectedWish?.name}</strong> updated <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(props.selectedGroup?.groupId, props.selectedWish?.wishId);
      } else {
        setMessage({ type: 'error', description: result.message });
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>

      <Grid container justifyContent='space-between'>
        <Grid item>
          <DialogTitle>Update Wish State</DialogTitle>
        </Grid>
        <Grid item style={{ textAlign: 'right' }}>
          <DialogTitle className={classes.subtitle}>{props.selectedWish?.name} - {props.selectedWish?.productName} {props.selectedWish?.bag}</DialogTitle>
        </Grid>
      </Grid>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <FormControl className={classes.formcontrol} fullWidth>
            <InputLabel id='state-label'>state</InputLabel>
            <Select
              labelId='state-label'
              id='state'
              label="state"
              value={state}
              onChange={(e: any) => setState(e.target.value)}
            >
                <MenuItem value={EState.created}>{EState.created}</MenuItem>
                <MenuItem value={EState.submitted}>{EState.submitted}</MenuItem>
                <MenuItem value={EState.rejected}>{EState.rejected}</MenuItem>
                <MenuItem value={EState.reviewed}>{EState.reviewed}</MenuItem>
                <MenuItem value={EState.approved}>{EState.approved}</MenuItem>
                <MenuItem value={EState.denied}>{EState.denied}</MenuItem>
                <MenuItem value={EState.scheduled}>{EState.scheduled}</MenuItem>
                <MenuItem value={EState.completed}>{EState.completed}</MenuItem>
                <MenuItem value={EState.cancelled}>{EState.cancelled}</MenuItem>
            </Select>
            <FormHelperText className={classes.errorText}>{errorState}</FormHelperText>
          </FormControl>

          <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
