import { EWishType } from '../../services/types';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FlareIcon from '@mui/icons-material/Flare';

export const WishIcon = (props: any) => {
  const { wishType } = props;
  switch (wishType) {
    case EWishType.wish: return <AutoAwesomeIcon color="disabled" />;
    case EWishType.appointment: return <FlareIcon color="info" />;
  }
  return <></>;
};
