import { useState, useEffect, useMemo } from 'react';

import { EBool } from '../../services/types';
import { deliverWish } from '../../services/wish';

import { AppMessage } from '../UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    fontWeight: 300,
    fontSize: '1em',
  },
}));

export const DeliverWishModal = (props: any) => {
  const [notes, setNotes] = useState('');
  const [deliveredAt, setDeliveredAt] = useState('');

  const [errorNotes, setErrorNotes] = useState('');
  const [errorDeliveredAt, setErrorDeliveredAt] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const deliverDate = useMemo(() => { if (deliveredAt) { const dt = new Date(deliveredAt); dt.setHours(12); return dt.toISOString() } else { return new Date().toISOString() } }, [deliveredAt]);

  const clearForm = () => {
    setNotes(props.selectedWish?.deliverNotes || '');
    setDeliveredAt(props.selectedWish?.deliveredAt || props.selectedWish?.scheduledAt || new Date().toISOString());
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorNotes('');
    setErrorDeliveredAt('');
  };

  useEffect(() => {
    clearForm();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedGroup, props.selectedWish])

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (deliveredAt.length === 0) {
      setErrorDeliveredAt('Enter the date');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleDeliver();
    }
  };

  const handleDeliver = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating Wish: <strong>${props.selectedWish?.name}</strong> ... ` });
    deliverWish(props.selectedWish.groupId, props.selectedWish.wishId, EBool.true, notes, deliverDate).then((result: any) => {
      if (result.status) {
        setMessage({ type: 'success', description: `Wish: <strong>${props.selectedWish?.name}</strong> delivered <small>at ${deliverDate}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(props.selectedGroup?.groupId, props.selectedWish?.wishId);
      } else {
        setMessage({ type: 'error', description: result.message });
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <DialogTitle>Product Delivered</DialogTitle>
        </Grid>
        <Grid item style={{ textAlign: 'right' }}>
          <DialogTitle className={classes.subtitle}>{props.selectedWish?.name} - {props.selectedWish?.productName} {props.selectedWish?.bag}</DialogTitle>
        </Grid>
      </Grid>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat='DD-MMM-YYYY'
              disableMaskedInput
              label='delivered on'
              value={deliveredAt}
              onChange={(val: any) => setDeliveredAt(val)}
              renderInput={(params: any) => (
                <TextField {...params}
                  id='deliveredAt'
                  error={errorDeliveredAt.length > 0}
                  helperText={errorDeliveredAt}
                  margin='normal'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            id='notes'
            label='notes'
            value={notes}
            error={errorNotes.length > 0}
            helperText={errorNotes}
            onChange={(e: any) => setNotes(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            multiline
            rows='4'
          />

          <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
