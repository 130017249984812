import { useState, useEffect } from 'react';

import { EBool } from '../../services/types';
import { approveWish } from '../../services/wish';

import { WishTitle } from '../Wish/WishTitle';
import { AppMessage } from '../UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    fontWeight: 400,
    fontSize: '0.8em',
  },
  notes: {
    fontSize: '0.8em',
  },
  formcontrol: {
    marginTop: theme.spacing(1),
  },
  formText: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.error.main
  },
}));

export const ApproveWishModal = (props: any) => {
  const [isApproved, setIsApproved] = useState('');
  const [notes, setNotes] = useState('');

  const [errorIsApproved, setErrorIsApproved] = useState('');
  const [errorNotes, setErrorNotes] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearForm = () => {
    setIsApproved(props.selectedWish?.isApproved || '');
    setNotes(props.selectedWish?.approveNotes || '');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorIsApproved('');
    setErrorNotes('');
  };

  useEffect(() => {
    clearForm();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedGroup, props.selectedWish])


  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (isApproved !== EBool.true && isApproved !== EBool.false) {
      setErrorIsApproved('Select one of the options above');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleApprove();
    }
  };

  const handleApprove = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating Wish: <strong>${props.selectedWish?.name}</strong> ... ` });
    approveWish(props.selectedWish.groupId, props.selectedWish.wishId, isApproved, notes).then((result: any) => {
      if (result.status) {
        setMessage({ type: 'success', description: `Wish: <strong>${props.selectedWish?.name}</strong> updated <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(props.selectedGroup?.groupId, props.selectedWish?.wishId);
      } else {
        setMessage({ type: 'error', description: result.message });
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <DialogTitle>Approve <WishTitle wishType={props.selectedWish?.wishType} /><br /><span className={classes.subtitle}>{props.selectedWish?.name} - {props.selectedWish?.productName} {props.selectedWish?.bag}</span></DialogTitle>
      <DialogContent>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <FormControl className={classes.formcontrol}>
            <RadioGroup
              id='isApproved'
              name="isApproved"
              value={isApproved}
              onChange={(e: any) => setIsApproved(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Approved" />
              <FormControlLabel value="false" control={<Radio />} label="Denied" />
            </RadioGroup>
            <FormHelperText className={classes.formText}>{errorIsApproved}</FormHelperText>
          </FormControl>

          <TextField
            id='notes'
            label='notes'
            value={notes}
            error={errorNotes.length > 0}
            helperText={errorNotes}
            onChange={(e: any) => setNotes(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            multiline
            rows='4'
          />
          <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
